import React, {useEffect, useState} from 'react';
import {IonContent, IonHeader, IonIcon, IonItem, IonModal, IonToolbar} from '@ionic/react';
import {arrowDown, arrowUp, ellipsisHorizontal, flask, helpSharp, send} from 'ionicons/icons';
import 'react-circular-progressbar/dist/styles.css';
import placeholder from "../imgs/placeholder_profile.png";
import chevronRight from "../imgs/chevron-right.png";
import helpdeskIcon from "../imgs/helpdesk.png";
import {Link} from "react-router-dom";
import {ApiService} from "../api/ApiService";
import {DriverProfile} from "../api/model/DriverProfile";
import {UserState, userStore} from "../api/AuthService";
import {UserUtils} from "../utils/UserUtils";
import { toastStore } from '../components/AppToast';
import AnimateHeight from 'react-animate-height';
import HelpDirect from './HelpDirect';

const ProfileInfo: React.FC = () => {

    const {driver, profilePictureUrl}: { driver: DriverProfile, profilePictureUrl: string } = userStore((state: UserState) => ({
        driver: state.driver,
        profilePictureUrl: state.profilePictureUrl
    }));

    const today = new Date();

  const [currentRate, setCurrentRate] = useState(0);
  const [daysUntilRaise, setDaysUntilRaise] = useState(0);
  const [payrollStartDate, setPayrollStartDate] = useState("");
  const [showLatest, setShowLatest] = useState(false);
  const [showFuel, setShowFuel] = useState(false);
  const [lowDEF, setLowDEF] = useState<string>('no');
  const [DEFLevel, setDEFLevel] = useState<string>("");
  const [fuelLevel, setFuelLevel] = useState<string>("");
  const [height1, setHeight1] = useState(false);

  const [gameData, setGameData] = useState({
    COST_CENTER: "-",
    HIRE_DATE: "-",
    HIRE_TENURE_DAYS: 0,
    MILES_LIFETIME: 0,
    MILES_MTD: 0,
    MILES_MTD_RANK: 0,
    MILES_WTD: 0,
    MILES_WTD_RANK: 0,
    MPG_MTD_COSTCENTER_GROUP: "-",
    MPG_MTD_MILES: 0,
    MPG_MTD_MPG: 0,
    MPG_MTD_RANK: 0,
    MPG_MTD_TRUCK_GROUP: "-",
    MPG_WTD_COSTCENTER_GROUP: "-",
    MPG_WTD_MILES: 0,
    MPG_WTD_MPG: 0,
    MPG_WTD_RANK: 0,
    MPG_WTD_TRUCK_GROUP: "-",
    MTD_TEAM: 0,
    PRETRIPS_MTD_RANK: 0,
    PRETRIPS_WTD_RANK: 0,
    WTD_TEAM: 0,
    exectime: 0  
  }
)

  useEffect(() => {
    ApiService.getPayrollOverview("profileInfo").then(it => {
      if (!it) {
        return;
      }
      let startDate = it.INITIAL_DATE.replace('T', ' ').slice(0, -14)
      setCurrentRate(it.CURRENT_FULL_SOLO_RATE);
      setDaysUntilRaise(it.DAYS_TILL_NEXT_RATE);
      setPayrollStartDate(startDate.substr(5) + "-" + startDate.substr(0, 4));
    })
    .catch(error => {
      console.error(error);
    });

    ApiService.getGamificationDriver2("profileInfo").then(it => setGameData(it as any))
    .catch(error => {
      console.error(error);
    });
  }, []);

  function toProperCase (str: string) {
    if (str !== undefined) {
      return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    } else {
      return "";
    }
  }

  const sendFuelRequest = async () => {
    if (fuelLevel !== "" && DEFLevel !== "") {
      const emailAddress = await getEmailAddress();
      const emailBody = `Driver ${localStorage.getItem('username')} has request a fuel soltuion on unit: ${userStore.getState().driver?.unit} <br/> Fuel Level: ${fuelLevel} <br/> Low on DEF: ${lowDEF} <br/>DEF Level: ${DEFLevel}`;
      ApiService.sendEmail("Fuel solution", {to: [emailAddress], subject: `Fuel Solution for Unit: ${userStore.getState().driver?.unit}`, body: emailBody}).then(() => {setShowFuel(false); setLowDEF("no"); setDEFLevel(""); setFuelLevel("")});
      ApiService.sendEventCommand("Fuel Request", "eventCheck", "User requested a fuel solution");
    } else {
      toastStore.getState().setToast('Please fill out all the fields');
    }
  }

  async function getEmailAddress() {
    const day = today.getDay();
    const hours = today.getHours()
    if (day !== 0 && day !== 6 && hours >= 7 && hours <= 16) {
      console.log("wsxi");
      return "wsxi-fuel@westernexp.com"
    } else {
      console.log("hotelfuel");
      return "HOTELSBUSTICKETSFUEL@westernexp.com"
    }
  }

  return (
    <IonItem lines="none" color="light">
       
       <IonModal isOpen={showLatest}>
          <IonHeader >
                <IonToolbar color="primary">
                  <p style={{fontFamily: "Montserrat, sans-seriff", padding: "0 8px"}} onClick={() => setShowLatest(false)}>Back</p>
                </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonItem color='light' lines="none">
              <div className='item-container'>
                <p>Latest Update</p>
                <h1><strong>Leader Boards!</strong></h1>
                <p className='subtitle'>Check out the leader boards to see the MPG standings. Do you have the best fuel efficiency in your division?!</p>
                  <Link className='western-button-link' to="/rewards">
                    <div className="space-between">
                      <p>Go to Division Standings</p>
                      <img src={chevronRight} style={{ height: "1.8vh"}}alt="arrow"/>
                    </div>
                  </Link>
              </div>
            </IonItem>
        </IonContent>
      </IonModal>
      <IonModal isOpen={showFuel}>
          <IonHeader>
                <IonToolbar color="primary">
                  <p style={{fontFamily: "Montserrat, sans-seriff", padding: "0 8px"}} onClick={() => setShowFuel(false)}>Back</p>
                </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonItem color='light' lines="none">
              <div className='item-container'>
                <h1><strong>Request Fuel Solution</strong></h1>
                <p className='subtitle'>Before requesting a fuel solution, please answer the following questions:</p>
                <IonItem color="transparent">
                  <p style={{ width: "60%", padding: '5px', alignItems: "center"}}>Current Fuel Level</p>
                  <div className="input-container">
                      <input id="trainee" className="input" type="text" placeholder=" " value={fuelLevel} onChange={e => setFuelLevel(e.target.value!)}/>
                      <p className="placeholder">Fuel Level</p>
                  </div>
                  </IonItem> 
                  <IonItem color="transparent">
                  <p style={{width: "60%", padding: '5px', alignItems: "center"}}>Are you low on DEF?</p>
                  <div className="input-container" style={{width: "25%", marginLeft: '15px'}}>
                      <select id="accident" className="input"  placeholder=" " value={lowDEF} onChange={e => {setLowDEF(e.target.value!);}} style={{ height: "20px", textAlign: "center"}}>
                          <option value={"no"}>No</option>
                          <option value={"yes"}>Yes</option>
                      </select>
                      <p className="placeholder" ></p>
                  </div>
                  </IonItem> 
                  <IonItem color="transparent" lines="none">
                  <p style={{width: "60%", padding: '5px', alignItems: "center"}}>DEF Level</p>
                  <div className="input-container">
                      <input id="trainee" className="input" type="text" placeholder=" " value={DEFLevel} onChange={e => setDEFLevel(e.target.value!)}/>
                      <p className="placeholder">DEF Level</p>
                  </div>
                  </IonItem> 
                  <div className="space-between" style={{background: "#fff", borderRadius: "10px", marginTop: "10px"}} onClick={e => {sendFuelRequest()}}>
                    <p style={{paddingLeft: "10px"}}>Request Solution</p>
                    <img src={chevronRight} style={{ height: "1.8vh", paddingRight: '8px'}}alt="arrow"/>
                  </div>
              </div>
          </IonItem> 
        </IonContent>
      </IonModal>
     <div className="item-container">
        <div>
            <div style={{ display: "flex", gap: "2vw", alignItems: "center", margin: "8px"}}>
                <img className="profile-photo" onError={({currentTarget}) => {
                    currentTarget.onerror = null;
                    currentTarget.src = placeholder
                }} src={profilePictureUrl} alt="avatar"/>
                <div style={{display: "flex", gap: "2vw"}}>
                    {!UserUtils.isTrainee() &&
                        <div className="game-stat">
                            <strong>{gameData.HIRE_TENURE_DAYS >= 0 && gameData.HIRE_TENURE_DAYS !== null ? gameData.HIRE_TENURE_DAYS.toLocaleString() : '-'} Days</strong>
                            <p>Tenure</p>
                        </div>
                    }
                    {!UserUtils.isTrainee() &&
                        <div className="game-stat">
                            <strong>{currentRate ? currentRate + '/Mi' : '-'}</strong>
                            <p>Solo Rate</p>
                        </div>
                    }
                    <div className="game-stat">
                        <strong>{driver?.unit || '-'}</strong>
                        <p>Unit</p>
                    </div>
                </div>
            </div>
        </div>
        <h5 className="green" style={{ margin: "8px"}}><b><span style={{ color: "black"}}>{toProperCase(driver.name)}</span> {driver.driverCode !== undefined ? '|' : ''} {driver.driverCode}</b></h5>
       
        <Link to="/currentload" className='western-button-link'>
            <div className="green" id="current-load-button">
                <p>Current Load</p>
                <img src={chevronRight} style={{ height: "1.8vh"}} alt="arrow"/>
            </div>
        </Link>

        <div className='western-button-link' onClick={() => setShowFuel(!showFuel)}>
            <div style={{background: "#fff", boxShadow: "0 4px 4px rgba(0,0,0,0.25)", marginTop: "15px", marginBottom: "15px"}}>
              <p><IonIcon icon={flask} style={{ margin: "auto 10px auto 10px", height: "1.8vh"}}></IonIcon>Request Fuel Solution</p>
            </div>
        </div>
        
        <div style={{ marginBottom: "14px", textAlign: "center"}} onClick={ e => {setHeight1(!height1); console.log("expanded");}} aria-expanded={ height1 !== false }>
            <div className='western-button-link'>
                <div className="green" id="current-load-button"  >
                    <p style={{paddingLeft: "15px"}}>Need Help?</p>
                    <img src={height1 == true ? arrowUp : arrowDown} style={{ height: "2vh", paddingRight: "15px"}} alt="arrow"/>
                </div>
            </div>
        </div>
          <AnimateHeight style={ height1 === true ? {border: "1px #dcdcdc solid", overflowY: 'scroll', borderRadius: "10px" } : { overflowY: 'scroll'}} duration={500} height={ height1 === true ? 'auto' : 0 } id="loadSection">
            <HelpDirect/>
          </AnimateHeight>
{/* 
        <div style={{ display: "grid", gridTemplateColumns: "70% 30%" }}>
          <Link to="/editprofile" className='western-button-link'>
              <div className="outline" id="centered">
                  <p>Edit Profile</p>
              </div>
          </Link>
          <div className='western-button-link' onClick={() => setShowLatest(!showLatest)}>
              <div id="centered" style={{ minWidth: "10vw"}}>
                <p><IonIcon icon={ellipsisHorizontal} style={{ color: showLatest ? "#212121" : "#666667", fontSize: 'min(3.6vw, 18px)'}}></IonIcon></p>
              </div>
          </div>
  
        </div> */}
        {/* <div style={{  fontFamily: 'Montserrat, sans-serif', fontWeight: '600', margin: "8px", display: "grid", gridTemplateColumns: "50% 50%"}}>
          <p>Start Date: </p>
          <p>Next Raise In: </p>
        </div> */}
       
      </div>
  
    </IonItem>
  );
};

export default ProfileInfo;
