import React, { useEffect, useState } from 'react'
import {config} from '../Constants';
import pretripIcon from "../imgs/pretrip.png"
import breakdownIcon from "../imgs/breakdown.png"
import scanIcon from '../imgs/work/qr-scan.png';
import passengerIcon from "../imgs/add-passenger.png"
import camera from "../imgs/camera.png"
import inspectionIcon from "../imgs/doc-check.png"
import {AppLauncher} from '@capacitor/app-launcher';
import {ApiService} from "../api/ApiService";
import {Link} from "react-router-dom";
import {userStore} from "../api/AuthService";
import { toastStore } from '../components/AppToast';

const Trip = () => {

    const [approvedDriver, setApprovedDriver] = useState(false);

    const approvedDriverArray = ["PILPA1", "PERD20", "BOUIB1", "WOOJO1", "SMARO1", "TIMAN1", "CESST1", "ROSDE5", "KRICH1", "HENDE4", "OVBTH1", "DAVCH4", "BRADA", "WHIGR1"]

    useEffect(() => {
        checkDrivers()
    }, [])

    function checkDrivers() {
        approvedDriverArray.forEach((element: string) => {
            if(userStore.getState().driver?.driverCode === element) setApprovedDriver(true);
        });
    }

    async function transfloClick() {
        ApiService.sendEventCommand("Tranflo Route", "eventCheck", "User has been routed to the Transflo app");
        const deepLink = `transflomobileplus://scan?recipientid=WSXI&sourceapp=westerndrivermobile&doctypes=TOD,BOL,TRP&driverid=${localStorage.getItem('username')?.toUpperCase()}&truckid=${userStore.getState().driver.unit}`;
        AppLauncher.openUrl({url: deepLink});
    }

    return (
        <div className="item-container">
            <div className="grid50">
            { approvedDriver ? 
            <Link to="/trailertracking" className="image-button">
                <div id="stock-map">
                    <p>Trailer Tracking</p>
                </div>
            </Link> :
            <div className="image-button" onClick={() => {toastStore.getState().setToast('Feature only available to select terminals');}}>
                <div id="stock-map">
                    <p>Trailer Tracking</p>
                </div>
            </div>
            } 
            <Link to="/photoupload" className="image-button">
                <div id="last-tarp">
                    <p>Tarp Photo</p>
                </div>
            </Link>
                <div>
                    <div className="western-button-link" onClick={e => {transfloClick()}}>
                        <div id="centered">
                            <p style={{ textAlign: "left"}}>Submit Paperwork<br/><span style={{ color: "#D0A024", fontSize: "12px"}}>Transflo</span></p>
                            <img src={scanIcon} alt="scan"/>
                        </div>
                    </div>
                    { localStorage.getItem("type") == "inspector" ? <Link className="western-button-link" to="/borderinspections">
                        <div>
                        <img src={camera} alt="key" className="link-icon"/><p>TLC Photos</p>
                        </div>
                    </Link> : null}
                </div>
                <div>
                    <Link className="western-button-link" to="/passenger">
                        <div>
                        <img src={passengerIcon} alt="key" className="link-icon"/><p>Add Passenger</p>
                        </div>
                    </Link>
                    <Link className="western-button-link" to="/leasepurchase">
                        <div>
                        <img src={inspectionIcon} alt="key" className="link-icon"/><p>Vehicle Inspection<br/><span style={{ color: "#D0A024", fontSize: "12px"}}>Lease Purchase Program Only</span></p>
                        </div>
                    </Link>
                </div>
            </div>

            {config.isPlatformSci ? <p style={{ margin: "0 8px", color: "#5096FF"}}>Pretrip and breakdowns must be completed on the Platform Science tablet.</p>: <div className="grid50">
            <Link to="/pretrip" className='western-button-link'>
                <div>
                    <img src={pretripIcon} alt="pretrip" className="link-icon"/>
                    <p>Pretrip</p>
                </div>
            </Link>
            <Link to="/breakdown" className='western-button-link'>
                <div>
                    <img src={breakdownIcon} alt="breakdown" className="link-icon"/>
                    <p>Breakdown</p>
                </div>
            </Link>
            </div> }
        </div>
    )
}

export default Trip
